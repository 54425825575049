export default {
    surveyAll:{
        url: 'survey/surveyAll', 
        method: 'post',
    },
    surveyGet:{
        url: 'survey/surveyGet', 
        method: 'post',
    },
    surveyGetByCode:{
        url: 'survey/surveyGetByCode/{id}', 
        method: 'get',
    },
    surveyAnswer:{
        url:'survey/insertAnswers',
        method:'post'
    },
    surveyList:{
        url: 'survey/surveyList', 
        method: 'post',
    },
    answerAll:{
        url: 'survey/answerList', 
        method: 'post',
    },
}