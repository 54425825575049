import auth from "@/middleware/auth";

export default [
    {
        path: "minhas-dividas",
        name: "minhasDividas",
        meta: { middleware: [auth] },
        component: () => import(/* webpackChunkName: "minhas-dividas" */ "./MinhasDividas"),
    }
];
