import auth from "@/middleware/auth";
import { routes as minhasDividas } from "./minhasDividas/";
import { routes as cadastro } from "./cadastro/";
import { routes as comprovante } from "./comprovante/";
import { routes as meusAcordos } from "./meusAcordos/";
import { routes as negociarDivida } from "./negociarDivida/";
import { routes as meusBoletos } from "./meusBoletos/";


export default [
    {
        path: "/dashboard",
        component: () => import(/* webpackChunkName: "dashboard-layout" */ "@/modules/layout/DashboardLayout"),
        children: [
            {
                path: "",
                name: "dashboard",
                meta: { middleware: [auth] },
                component: () => import(/* webpackChunkName: "dashboard" */ "./Dashboard")
            },
            ...minhasDividas,
            ...meusAcordos,
            ...cadastro,
            ...negociarDivida,
            ...meusBoletos,
            ...comprovante,
        ]
    }
];