import { store as auth } from '@/modules/auth';
import { store as layout } from '@/modules/layout';
import { store as minhasDividas } from '@/pages/dashboard/minhasDividas';
import { store as meusBoletos } from '@/pages/dashboard/meusBoletos';
import { store as comprovante } from '@/pages/dashboard/comprovante';
import { store as cadastro } from '@/pages/dashboard/cadastro';
import { store as meusAcordos } from '@/pages/dashboard/meusAcordos';   
import { store as survey } from '@/pages/pesquisa';  

export default {
    auth,
    layout, 
    minhasDividas,
    meusBoletos,
    cadastro,
    comprovante,
    meusAcordos,
    survey
}