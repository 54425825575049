import { routes as home } from '@/pages/home';
import { routes as dashboard } from '@/pages/dashboard';
import { routes as sobre } from '@/pages/sobre';
import { routes as mailing } from '@/pages/landing';
import { routes as survey } from '@/pages/pesquisa';
import { routes as comprovante } from '@/pages/dashboard/comprovante';

export default [
    ...home,
    ...dashboard,
    ...sobre,
    ...mailing,
    ...survey,
    ...comprovante,
    {
        path: "/:pathMatch(.*)",
        name: "notFound",
        component: () =>
          import(/* webpackChunkName: "not-found" */ "@/pages/NotFound"),
    },
    
];