import * as types from './mutation-types'

export default {
    [types.SET_STEP](state, step) {
        state.step = step
    },
    [types.SET_CURRENTCOMPONENT](state, currentComponent) {
        state.currentComponent = currentComponent
    },
    [types.SET_DIVIDAS](state, dividas) {
        state.dividas = dividas
    },
    [types.SET_BOLETO_EXPRESSO](state, boletos) {
        state.boletos = boletos
    },
    [types.SET_NEGOCIACAO](state, deal) {
        state.negociacao = deal
    },
    [types.SET_ACORDO](state, acordo) {
        state.acordo = acordo
    },
}