import { survey } from '@/http';
import services from '@/http';
import * as types from './mutation-types';

export const ActionSurveyAll  = async ({_}, payload) => {
    console.log(_);

    survey.surveyAll.data = payload;
    return services.http.request(survey.surveyAll)
        .then(async response => {
           
            return response.data;          
        })
        .catch((error) => {           
            throw error;
        });
}

export const ActioAnswerAll  = async ({_}, payload) => {
    console.log(_);

    survey.answerAll.data = payload;
    return services.http.request(survey.answerAll)
        .then(async response => {
           
            return response.data;          
        })
        .catch((error) => {           
            throw error;
        });
}

export const ActionSurveyGet  = async ({_}, payload) => {
    console.log(_);
    survey.surveyGet.data = payload;
    return services.http.request(survey.surveyGet)
        .then(async response => {
            return response.data;          
        })
        .catch((error) => {           
            throw error;
        });
}

export const ActionSurveyGetByCode  = async ({_}, surveyCode) => {
    console.log(_);
    survey.surveyGetByCode.url = 'survey/surveyGetByCode/{id}';
    survey.surveyGetByCode.url = survey.surveyGetByCode.url.replace('{id}',surveyCode);

    return services.http.request(survey.surveyGetByCode)
        .then(async response => {
            return response.data;          
        })
        .catch((error) => {           
            throw error;
        });
}

export const ActionSurveyAnswer  = async ({_}, payload) => {
    console.log(_);
    survey.surveyAnswer.data = payload;
    return services.http.request(survey.surveyAnswer)
        .then(async response => {
            return response;          
        })
        .catch((error) => {           
            throw error;
        });
}

export const ActionSurveyList = async ({_}, payload) => {
    console.log(_);

    survey.surveyList.data = payload;
    return services.http.request(survey.surveyList)
        .then(async response => {           
            return response.data.results; 
        })
        .catch((error) => {           
            throw error;
        });
}

export const ActionSetPesquisa = ({ commit }, comp) => {
    commit(types.SET_CURRENTPESQUISA, comp);
}