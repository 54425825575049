<template>
    <!-- <component v-bind:is="layout"></component> -->
  <v-overlay :value="loading" v-if="loading">
    <v-progress-circular
      indeterminate
      size="64"
      color="accent"
    ></v-progress-circular>
  </v-overlay>
  <router-view v-else-if="!isLoaded">

  </router-view>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import axios from 'axios';

export default {
  name: "App",
  data() {
    return {
      isLoaded: true,
      baseURL: process.env.VUE_APP_API_ADM_URL,
      imageApiURL: process.env.VUE_APP_API_ADM_IMAGE_URL,
      apiAdmUserName: process.env.VUE_APP_API_USERNAME,
      apiAdmUserPassword: process.env.VUE_APP_API_PASSWORD,
      deviceName: process.env.VUE_APP_API_DEVICE,
      token: null,
      ownerID: process.env.VUE_APP_OWNER_ID,
      heroImage: null
    };
  },
  computed: {
    ...mapState('auth', ['loading']),
  },

  methods: {
    ...mapActions('auth', ['ActionSetToken']),
  },
  async created() {
    const auth = { 
      UserName: this.apiAdmUserName,
      Password: this.apiAdmUserPassword,
      DeviceName: this.deviceName

    };
    
    // var pathName = window.location.pathname;

    if(!this.$store.getters['auth/hasToken']){
      const response = await axios.post(this.baseURL+"auth/loginApi", auth);
      this.token = response.data.results.token;
      this.ActionSetToken(this.token);

      // const USER_TOKEN = response.data.results.token
      // const AuthStr = 'Bearer '.concat(USER_TOKEN)
      // const URL = this.baseURL.concat('ownerTheme/getThemeByOwnerCode');
      // const ownerTheme = { 
      //   "OwnerID": this.ownerID,
      //   "ThemeType": "Home",
      //   "ThemeID":this.$route.query.themeId //se enviar o themaid por parametro carrega tmb... funcionar para o preview
      // };

      // const themeResponse = await axios
      //   .post(URL, ownerTheme,
      //     { headers: { Authorization: AuthStr } })
      //   .then(response => {
      //       return response.data.results;
      //     })
      //   .catch((error) => {
      //       console.log(error)
      //     });

      // window.jQuery("head").append('<style type="text/css"></style>');
      // var newStyleElement = window.jQuery("head").children(':last');

      // if(themeResponse.length > 0) {

      //   if(themeResponse[0].HeroImage != ''){
      //     localStorage.removeItem("heroImg");
      //     localStorage.heroImg = this.imageApiURL.concat(themeResponse[0].FilePath).concat('/').concat(themeResponse[0].HeroImage); 
      //   }

      //   if(themeResponse[0].DashBoardImage != ''){
      //     localStorage.removeItem("dashBoardImage");
      //     localStorage.dashBoardImage = this.imageApiURL.concat(themeResponse[0].FilePath).concat('/').concat(themeResponse[0].DashBoardImage); 
      //   }
      
      //   if(themeResponse[0].IsFooterDegrade) {
      //     newStyleElement.html('.footer1{'+
      //       'background: linear-gradient(0deg, '+themeResponse[0].FooterColor1+' 0%, '+themeResponse[0].FooterColor2+' 100%) !important;'+
      //       'font-size: 14px !important;'+
      //       'color: #fff !important;}'+

      //       '.navbar{background-color:'+themeResponse[0].HeaderColor+' !important;}'+

      //       '.home-style{'+
      //         'background-color: #EFE9E5 !important;'+
      //         'background-size: cover !important;'+
      //         'background-image: url("'+this.imageApiURL.concat(themeResponse[0].FilePath).concat('/').concat(themeResponse[0].BackGroundImage)+'");'+
      //         'background-position: top right !important;'+
      //         'background-repeat: repeat !important;'+
      //         'padding-bottom: 8% !important;}'+
      //         'padding-top: 11% !important;}'
      //       );
      //   }else{
      //     newStyleElement.html(
      //       '.navbar{background-color:'+themeResponse[0].HeaderColor+' !important;}'+
      //       '.footer1{background:'+themeResponse[0].FooterColor1+' !important;}'+
      //       '.home-style{'+
      //         'background-color: #EFE9E5 !important;'+
      //         'background-size: cover !important;'+
      //         'background-image: url("'+this.imageApiURL.concat(themeResponse[0].FilePath).concat('/').concat(themeResponse[0].BackGroundImage)+'");'+
      //         'background-position: top right !important;'+
      //         'background-repeat: repeat !important;'+
      //         'padding-bottom: 8% !important;}'+
      //         'padding-top: 11% !important;}'
      //     );
      //   }      
      // }else{
      //   localStorage.removeItem("heroImg");
      //   localStorage.removeItem("dashBoardImage");
      //   newStyleElement.html(
      //       '.home-style{'+
      //         'background-color: #EFE9E5 !important;'+
      //         'background-size: cover !important;'+
      //         'background-image: url("/img/lp1.jpg") !important;'+
      //         'background-position: top right !important;'+
      //         'background-repeat: repeat !important;'+
      //         'padding-bottom: 8% !important !important;}'+
      //         'padding-top: 11% !important !important;}'
      //       );
      // }
    }

    this.isLoaded = false;
  },
  components: {
  }
};

</script>
