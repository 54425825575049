import auth from "@/middleware/auth";

export default [
    {
        path: "comprovante",
        name: "comprovante",
        meta: { middleware: [auth] },
        component: () => import(/* webpackChunkName: "minhas-dividas" */ "./Comprovante"),
    }
];
