export default {
    insertProofPayment: {
        url: 'debtor/saveProofPayment', 
        method: 'post', 
    },
    listTypeProofPayment:{
        url: 'debtor/listTypeProofPayment', 
        method: 'get',  
    },
    statusProofPayment: {
        url: 'debtor/getProofPaymentStatus',
        method: 'post',
    },
    getProofPaymentDebtor: {
        url: 'debtor/getProofPaymentDebtor',
        method: 'post',
    },
    actionDocument: {
        url: 'debtor/actionDocument',
        method: 'post',
    },
    approveOrRefuseDoc: {
        url: 'debtor/approveOrRefuseDoc',
        method: 'post',
    }
}