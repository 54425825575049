import { auth } from '@/http';
import services from '@/http';
import { hasError } from "@/utils/helpers";
import * as types from './mutation-types';
import * as storage from '../storage';

export const ActionSendPromoCode = (context, payload) => {
    auth.sendPromoCode.data = payload;
    return services.http.request(auth.sendPromoCode)
                .then((result) => {
                    return result;
                })
                .catch((err) => {
                    console.log(err)
                    if(err.data.message) {
                        throw new Error('Recurso indisponível.');
                    } else {
                        return err;
                    }
                });
}

export const ActionGetMailingFirstName = async ({ dispatch }, code) => {
    console.log(dispatch);
    auth.getMailingFirstName.url = auth.getMailingFirstName.url.replace('{code}',code);
    return services.http.request(auth.getMailingFirstName)
                .then((result) => {
                    return result;
                })
                .catch((err) => {
                    return err;
                });
}

export const ActionGetContactsByDocNumber = (context, payload) => {
    auth.getContacts.data = payload;
    return services.http.request(auth.getContacts)
                .then((result) => {
                    return result;
                }).catch((err) => {
                    return err;
                });
}

export const ActionCreateDebtor = (context, payload) => {
    auth.insertDebtor.data = payload;
    return services.http.request(auth.insertDebtor);
}

export const ActionIsHomolog = () => {
    return process.env.VUE_APP_HOMOLOG;
}
export const ActionDoRegisterSession = () => {
    // registra CSRF Cookies
    return services.http.request(auth.registerCookie);
}

export const ActionMailingDoLogin = async ({ dispatch }, payload) => {
    //dispatch('ActionSetLoading', true);
    auth.mailinglogin.data = payload;
    return await services.http.request(auth.mailinglogin)
        .then(async response => {
            dispatch("ActionSetGuest", { value: "isNotGuest" });
            dispatch("ActionSetToken", response.data.token);
            
            // await services.http.request(auth.getUser)
            //     .then(response => {
            //         dispatch('ActionSetUser', response.data.results);
            //         //dispatch('ActionSetLoading', false);
            //     })
            //     .catch(error => {
            //         dispatch('ActionSetError', hasError(error));
            //     });
                return response.data;
        })
        .catch((error) => {
            dispatch('ActionSetError', hasError(error));
            //dispatch('ActionSetLoading', false);
            throw error;
        });
}


export const ActionGetIPAddress = async () => {
    return await fetch('https://api.ipify.org?format=json')
    .then(x => x.json())
    .then(({ ip }) => {
        return ip;
    });
}


export const ActionDoLogin = async ({ dispatch }, payload) => {
    //dispatch('ActionSetLoading', true);
    auth.login.data = payload;
    await services.http.request(auth.login)
        .then(async response => {
            dispatch("ActionSetGuest", { value: "isNotGuest" });
            dispatch("ActionSetToken", response.data.token);
            
            // await services.http.request(auth.getUser)
            //     .then(response => {
            //         dispatch('ActionSetUser', response.data.results);
            //         //dispatch('ActionSetLoading', false);
            //     })
            //     .catch(error => {
            //         dispatch('ActionSetError', hasError(error));
            //     });
        })
        .catch((error) => {
            dispatch('ActionSetError', hasError(error));
            //dispatch('ActionSetLoading', false);
            throw error;
        });
}

export const ActionGetUser = async ({ dispatch }) => {
    return await services.http.request(auth.getUser)
        .then(response => {
            dispatch('ActionSetUser', response.data.results);
            dispatch('ActionSetLoading', false);
        })
        .catch(error => {
            dispatch('ActionSetError', hasError(error));
        });
}

export const ActionDoLogout = ({ dispatch }) => {
    storage.setHeaderToken('');
    storage.deleteLocalToken();
    dispatch('ActionSetUser', null);
    dispatch('ActionSetToken', null);
    dispatch("ActionSetGuest", { value: "isGuest" });
    location.reload();
}

export const ActionCheckToken = ({dispatch, state}) => {
    dispatch('ActionSetLoading', true);
    if (state.token) {
        dispatch('ActionSetLoading', false);
        return Promise.resolve(state.token);
    }

    const token = storage.getLocalToken();
    
    if(!token) {
        dispatch('ActionSetLoading', false);
        return Promise.reject(hasError("Token Inválido!"));
    }

    dispatch('ActionSetToken', token);
    return dispatch('ActionGetUser');
}


export const ActionGetWhatsText = ({dispatch}) => {
    dispatch('ActionSetLoading', true);
    return services.http.request(auth.whatsText)
        .then(response => {
            dispatch('ActionSetLoading', false);
            return response.data.results;
        })
        .catch((err) => {
            dispatch('ActionSetLoading', false);
            return err;
        });
}


export const ActionSetUser = ({ commit }, user) => {
    commit(types.SET_USER, user);
}

export const ActionSetLoading = ({ commit }, loading) => {
    commit(types.SET_LOADING, loading);
}

export const ActionSetError = ({ commit }, error) => {
    commit(types.SET_ERROR, error);
}

export const ActionSetCurrentForm = ({ commit }, currentForm) => {
    commit(types.SET_CURRENTFORM, currentForm);
}

export const ActionSetContacts = ({ commit }, contacts) => {
    commit(types.SET_CONTACTS, contacts);
}

export const ActionSetWhatsText = ({ commit }, text) => {
    commit(types.SET_WHATSTEXT, text);
}

export const ActionSetGuest = (context, {value}) => {
    window.localStorage.setItem("guest", value);
}

export const ActionSetToken = ({commit}, token) => {
    storage.setLocalToken(token);
    storage.setHeaderToken(token);
    commit(types.SET_TOKEN, token);
}

export const isGuest = () => {
    const storageItem = window.localStorage.getItem("guest");
    if (!storageItem) return false;
    if (storageItem === "isGuest") return true;
    if (storageItem === "isNotGuest") return false;
}