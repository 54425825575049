import { services as auth } from '@/modules/auth';
import { servicesUser as user } from '@/pages/dashboard';
import { services as divida } from '@/pages/dashboard/minhasDividas';
import { services as acordo } from '@/pages/dashboard/meusAcordos';
import { services as cadastro } from '@/pages/dashboard/cadastro';
import { services as comprovante } from '@/pages/dashboard/comprovante';
import { services as survey } from '@/pages/pesquisa';
export default {
    auth,
    user,
    divida,
    acordo,
    cadastro,
    comprovante,
    survey
}