import axios from "axios";
import store from "@/store";
import { Object } from "core-js";
import servicesRoutes from "./services";
import pkg from '../../package';

export const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: { 
    'X-Requested-With': 'XMLHttpRequest',
    'X-Http-Owner-Id': process.env.VUE_APP_OWNER_ID
  }
})

http.interceptors.response.use(
  (response) => {
    let fe_version = pkg.version;
    if(fe_version !== localStorage.getItem('fe-version')){
      localStorage.setItem('fe-version', fe_version);
      window.location.reload();
    }

    return response;
  },
  function(error) {
    if ((error.response.status === 401 || error.response.status === 419)
    && error.response.config.url != 'updatePromoCode' 
    && error.response.config.url != 'mailings/token' 
    && error.response.config.url != 'auth/contacts'
    && error.response.config.url != 'auth/validateFourNumbers'
    && error.response.config.url != 'auth/sanctum/token') {
      store.dispatch("auth/ActionDoLogout");
  }
    return Promise.reject(error.response);
  }
);

// rotas API organizadas
let services = {};

Object.keys(servicesRoutes).map((route) => {
  services[route] = servicesRoutes[route]
});

export const auth = services.auth;
export const divida = services.divida;
export const user = services.user;
export const cadastro = services.cadastro;
export const acordo = services.acordo;
export const comprovante = services.comprovante;
export const survey = services.survey;
export const setBearerToken = token => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export default {
  http,
};