export default {
    findEmpresa: { 
        url: 'users/{user_id}/empresa', 
        method: 'get', 
    },
    findDividas: { 
        url: 'accounts', 
        method: 'get', 
    },
    findBoletoExpresso: { 
        url: 'offers/express', 
        method: 'get', 
    },
    sendSmsOrEmail: { 
        url: 'sendOffer', 
        method: 'post', 
    },
    sendLogAccess: { 
        url: 'log/save', 
        method: 'post', 
    },
    downloadBill: { 
        url: 'boleto?ContratoParcela={contratoParcela}&LinhaDigitavel={linhaDigitavel}',
        method: 'get', 
    },
    sendLogAccessWithoutToken: { 
        url: 'auth/log/save', 
        method: 'post', 
    },
    dividaNegociar: { 
        url: 'offers?ContractNumber=', 
        method: 'get', 
    },
    findDividasPagination: { 
        url: 'users/{user_id}/dividas?page=', 
        method: 'get', 
    }
}