import guest from "@/middleware/guest";

export default [
  {
      path: "",
      component: () => import(/* webpackChunkName: "DashboardLayout" */ "@/modules/layout/LandingLayout"),
      children: [
          {
              path: "/survey",
              name: "survey",
              meta: { middleware: [guest] },
              component: () => import(/* webpackChunkName: "home" */ "./Pesquisa"),
          },
      ]
  },
];
