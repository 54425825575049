import services from '@/http';
import { comprovante, user } from '@/http';
import * as types from './mutation-types';


export const ActionUploadProofPayment = (context, payload) => {
    comprovante.insertProofPayment.data = payload;
    return services.http.request(comprovante.insertProofPayment);
}

export const ActionListTypeProofPayment = async () => {
    return services.http.request(comprovante.listTypeProofPayment)
        .then(async response => {
            return response.data.results;          
        })
        .catch((error) => {           
            throw error;
        });
}

export const ActionGetStatusProofPayment = async (_, id) => {
    comprovante.statusProofPayment.url = 'debtor/getProofPaymentStatus/' + id;

    return services.http.request(comprovante.statusProofPayment)
        .then(async response => {
           
            return response.data.results;          
        })
        .catch((error) => {           
            throw error;
        });
}

export const ActionSendEmailOrSmsText = (context, payload) => {
    user.sendSmsOrEmail.data = payload;
    console.log(payload);
    
    return services.http.request(user.sendSmsOrEmail)
        .then(response => {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const ActionSendAccessLog = (context, payload) => {
    user.sendLogAccess.data = payload;

    return services.http.request(user.sendLogAccess)
        .then(response => {
            return response;
        })
        .catch((err) => {
            return err;
        });
}


export const ActionSendAccessLogWithoutToken = (context, payload) => {
    user.sendLogAccessWithoutToken.data = payload;

    return services.http.request(user.sendLogAccessWithoutToken)
        .then(response => {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const ActionFindBoletoExpress = ({ dispatch }) => {
    return services.http.request(user.findBoletoExpresso)
        .then(response => {
            dispatch("ActionSetBoletoExpresso", response.data.results);
            return response;
        })
}


export const ActionGetProofPaymentDebtor = (context, payload) => {
    comprovante.getProofPaymentDebtor.data = payload;

    return services.http.request(comprovante.getProofPaymentDebtor)
        .then(response => {
            return response.data.results;
        })
        .catch((err) => {
            return err;
        });
}

export const ActionDocument = async (_, payload) => {
    console.log(_);
    comprovante.actionDocument.data = payload;

    return await services.http.request(comprovante.actionDocument)
        .then(async response => {
            return response;          
        })
        .catch((error) => {           
            throw error;
        });
}

export const ActionApproveOrRefuseDocument = async (_, payload) => {
    console.log(_);
    comprovante.approveOrRefuseDoc.data = payload;

    return await services.http.request(comprovante.approveOrRefuseDoc)
        .then(async response => {
            return response;          
        })
        .catch((error) => {           
            throw error;
        });
}


export const ActionSetBoletoExpresso = ({ commit }, boletos) => {
    commit(types.SET_BOLETO_EXPRESSO, boletos);
}


export const ActionSetDefaultStates = ({commit}) => {
    commit(types.SET_BOLETO_EXPRESSO, []);
}
